import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { App as AppType } from "/app/src/models";
import { useTranslation } from "react-i18next";

export default function Apps({
  availableAppSettings,
}: {
  availableAppSettings: AppType[];
}) {
  const { t } = useTranslation();
  return (
    <Row gutter={[24, 24]}>
      {availableAppSettings.map((app) => {
        const name = app.name as string;
        const appSelector = `${name.replace(" ", "_").toLowerCase()}`;

        return (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            key={app.id}
          >
            <div className="appBox" id={appSelector}>
              <div className="icon" />
              <div className="info">
                <h3>
                  <Link to={`/settings/apps/${app.id}`}>
                    {t(`translation:${appSelector}`, appSelector)}
                  </Link>
                </h3>
                {app.description}
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}
