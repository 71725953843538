import { useCallback } from "react";
import { Row, Col } from "antd";
import { hueService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import { toast } from "sonner";

/**
 * Open a new window with the given URL
 */
function openURL(url: string) {
  if (typeof window !== "undefined") {
    window.open(url);
  }
}

export default function NewIntegration() {
  const { t } = useTranslation();

  const connectBridge = useCallback(() => {
    hueService.getURL({}).then((response) => {
      if (response.errors) {
        toast.error(response.errors[0]);
      } else {
        openURL(response.url);
      }
    });
  }, []);

  return (
    <div className="newIntegration">
      <Row justify="start">
        <Col span={12}>
          <NextButton color="primary" onClick={connectBridge} size="md">
            {t("translation:add")} {t("translation:new_bridge")}
          </NextButton>
        </Col>
      </Row>
    </div>
  );
}
