import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Checkbox, Form, Input, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { convertStringToBoolean, simpleSchemaBuilder } from "/app/src/helpers";
import DataHook from "./dataHook";
import ReportColumn from "./fields/dynamicFields/reportColumn";
import {
  IconBuilder,
  IconToolTip,
} from "/app/src/components/icons/IconBuilder";
import { useNavigate } from "react-router-dom";
interface GeneralSettingsFormValues {
  number?: string | number;
  token?: string;
  reportId?: number;
  creationMode?: string;
  values?: (string | number)[];
  value?: boolean;
  name?: string;
}

/**
 * ValuesValidator is a React component that validates the "values" field
 * in a form using Formik's context. It triggers validation and marks the
 * field as touched whenever the `values` prop changes.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {any} props.values - The values to be validated.
 * @returns {null} This component does not render anything.
 */
function ValuesValidator({ values }) {
  const { validateField, setFieldTouched } =
    useFormikContext<GeneralSettingsFormValues>();
  useEffect(() => {
    validateField("values");
    setFieldTouched("values", true);
  }, [setFieldTouched, validateField, values]);
  return null;
}

/**
 *
 * @param param0 integration
 * @returns General settings form
 */
export default function GeneralSettings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    reports,
    creationMode,
    internalMappingSettingValue,
    autoSendSettingValue,
    hasCustomStatuses,
  } = DataHook(integration);
  const navigate = useNavigate();

  const initialValues = useMemo(() => {
    if (!internalMappingSettingValue) {
      return undefined;
    }
    return internalMappingSettingValue.split(";").map((item) => {
      const parsedInt = parseInt(item, 10);
      return isNaN(parsedInt) ? item : parsedInt;
    });
  }, [internalMappingSettingValue]);

  const labelContent = useMemo(
    () => (
      <>
        {t("translation:auto_send_order")}
        <IconToolTip
          content={t("translation:auto_send_order_description")}
          className="ml-1"
        />
      </>
    ),
    [t],
  );
  const goToReport = useCallback(() => {
    // navigate to the order page
    navigate(`/reports/${integration.reportId}`);
  }, [navigate, integration.reportId]);

  const generalSettingsForm: (
    props: FormikProps<GeneralSettingsFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting, values }) => (
      <Form layout="vertical">
        <ValuesValidator values={values} />
        <Row className="pb-3">
          <Col className="text-lg" span={8}>
            {t("translation:general_settings")}
          </Col>
          <Col span={8} offset={8}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={6}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="number"
              label={t("translation:order_builder_creation_type")}
              hasFeedback={false}
            >
              <Select name="number" size="large">
                <Select.Option value="manual">
                  {t("translation:manual")}
                </Select.Option>
                <Select.Option value="automatic">
                  {t("translation:automatic")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="token"
              label={t("translation:order_creation_type")}
              hasFeedback={false}
            >
              <Select name="token" size="large">
                <Select.Option value="Pick">
                  {t("translation:pick")}
                </Select.Option>
                <Select.Option value="Put">
                  {t("translation:put")}
                </Select.Option>
                <Select.Option value="Count">
                  {t("translation:count")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="reportId"
              label={t("translation:report_build_form")}
              hasFeedback={false}
            >
              <Select
                name="reportId"
                placeholder={t("translation:select_report")}
                size="large"
                onClick={goToReport}
                suffixIcon={<IconBuilder icon="LinkExternal" />}
                open={false}
              >
                {reports?.map((report) => (
                  <Select.Option key={report.id} value={report.id}>
                    {report.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="creationMode"
              label={t("translation:creation_mode")}
              hasFeedback={false}
            >
              <Select name="creationMode" size="large">
                <Select.Option value="line">
                  {t("translation:line")}
                </Select.Option>
                <Select.Option value="order">
                  {t("translation:order")}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <ReportColumn
              integration={integration}
              dynamic
              allowCurrentTime
              label={t("translation:internal_name_mapping")}
              size="large"
            />
          </Col>
          <Col span={8}>
            <Form.Item name="value" label={labelContent} hasFeedback={false}>
              <Checkbox disabled={!hasCustomStatuses} name="value" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    ),
    [goToReport, hasCustomStatuses, integration, labelContent, reports, t],
  );
  const getAutoSendValue = useCallback(() => {
    if (autoSendSettingValue !== undefined) {
      return convertStringToBoolean(autoSendSettingValue);
    }
    return !hasCustomStatuses;
  }, [autoSendSettingValue, hasCustomStatuses]);

  return (
    <div className="box mt-3">
      <Formik
        component={generalSettingsForm}
        initialValues={{
          number: integration.number,
          token: integration.token,
          reportId: integration.reportId,
          creationMode: creationMode || "order",
          name: integration.name,
          values: initialValues,
          value: getAutoSendValue(),
        }}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={simpleSchemaBuilder([
          { name: "number", type: "string", required: true },
          { name: "token", type: "string", required: true },
          { name: "reportId", type: "number", required: true },
          { name: "creationMode", type: "string", required: false },
          { name: "name", type: "string", required: true },
          { name: "values", type: "array", required: true },
        ])}
      />
    </div>
  );
}
