import React, { useCallback } from "react";
import { Row, Col } from "antd";
import { toast } from "sonner";
import { reportService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { buildParams } from "/app/src/helpers/params";
import { useQueryClient } from "@tanstack/react-query";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Shows the controls for the reports page
 * Controls include All Reports H1, New Report, Import Report
 * @param param0 toggleNew, activeNew, goToReport, toggleImportReport, activeImportReport
 * @returns
 */
export default function Controls({
  toggleNew,
  activeNew,
  goToReport,
  toggleImportReport,
  activeImportReport,
}: {
  toggleNew: () => void;
  activeNew: boolean;
  goToReport: (reportId: number) => void;
  toggleImportReport: () => void;
  activeImportReport: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  /**
   *
   * @param fileName string
   * @returns validation for the file name
   */
  function validateFileName(fileName: string) {
    return fileName.includes("itempath") && fileName.includes(".json");
  }

  /**
   * Handler that reads the file and calls API to import the report
   * Then invalidates the cache and navigates to the new report
   * @param e click event
   */
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileReader = new FileReader();
      if (e.currentTarget?.files && e.currentTarget.files.length > 0) {
        if (validateFileName(e.currentTarget.files[0].name)) {
          fileReader.readAsText(e.currentTarget.files[0], "UTF-8");
          fileReader.onload = (e) => {
            if (e.target?.result) {
              const data = JSON.parse(e.target.result as string);
              reportService
                .importReport(data, buildParams({ import: "True" }))
                .then((response) => {
                  queryClient.invalidateQueries({ queryKey: ["reports"] });
                  queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
                  goToReport(response.report.id);
                });
            }
          };
        } else {
          toast.error(t("translation:incorrect_file_format"));
        }
      }
    },
    [goToReport, queryClient, t],
  );

  return (
    <div className="controls">
      <Row>
        <Col span={8}>
          <h1>{t("translation:all_reports")}</h1>
        </Col>

        <Col span={16}>
          <div className="controlButtons">
            {activeImportReport ? (
              <>
                <input type="file" onChange={handleChange} />
                <NextButton
                  size="sm"
                  variant="bordered"
                  color="default"
                  onClick={toggleImportReport}
                  className="hover:border-primary-default hover:text-primary-default bg-white"
                >
                  {t("translation:cancel")}
                </NextButton>
              </>
            ) : (
              <NextButton
                size="sm"
                variant="bordered"
                color="primary"
                onClick={toggleImportReport}
                className="hover:border-primary-default hover:text-primary-default bg-white"
              >
                {t("translation:import")} {t("translation:template")}
              </NextButton>
            )}
            {activeNew ? (
              <NextButton
                size="sm"
                variant="bordered"
                color="primary"
                onClick={toggleNew}
              >
                {t("translation:cancel")}
              </NextButton>
            ) : (
              <NextButton
                size="sm"
                variant="solid"
                color="primary"
                onClick={toggleNew}
              >
                {t("translation:new_report")}
              </NextButton>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
