import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@heroui/react";

export default function ModalBuilder({
  warning,
  modalHeader,
  modalBody,
  modalFooter,
}: {
  warning?: boolean;
  modalHeader?: string;
  modalBody?: string;
  modalFooter?: React.ReactNode;
}) {
  return (
    <>
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center">
            {warning && (
              <IconBuilder
                icon="ExclamationCircle"
                color="#faad14"
                size={32}
                className="pr-2"
              />
            )}{" "}
            {modalHeader}
          </div>
        </ModalHeader>
        <ModalBody>{modalBody}</ModalBody>
        <ModalFooter>{modalFooter}</ModalFooter>
      </ModalContent>
    </>
  );
}
