import { useNavigate } from "react-router-dom";
import { workflowService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import NextModal from "/app/src/components/NextUi/Modal";
import { useDisclosure } from "@heroui/react";
import ModalBuilder from "../NextUi/helpers/ModalBuilder";
import { useCallback } from "react";

export default function DeleteForm({
  workflowId,
}: {
  workflowId: number | undefined;
}) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    workflowService.deleteSingle(workflowId).then(() => {
      navigate("/workflows");
    });
  }, [workflowId, navigate]);

  return (
    <>
      <NextButton
        fullWidth
        size="sm"
        color="default"
        variant="bordered"
        onClick={onOpen}
        className="border-[#a12121] text-[#a12121] bg-white mb-[20px]"
      >
        {t("translation:delete")} {t("translation:workflow")}
      </NextButton>
      <NextModal
        disableAnimation={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
      >
        {ModalBuilder({
          warning: true,
          modalHeader: t("translation:confirm_delete_workflow"),
          modalBody: t("translation:delete_workflows_tip"),
          modalFooter: (
            <>
              <NextButton
                size="sm"
                color="default"
                variant="bordered"
                onClick={onOpenChange}
                className="hover:border-primary-default hover:text-primary-default"
              >
                {t("translation:do_not_delete")}
              </NextButton>
              <NextButton size="sm" color="red" onClick={handleDelete}>
                {t("translation:delete")}
              </NextButton>
            </>
          ),
        })}
      </NextModal>
    </>
  );
}
