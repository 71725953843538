import React from "react";
import { Pagination } from "@heroui/react";

interface NextPaginationProps {
  variant?: "flat" | "bordered" | "light" | "faded";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  size?: "sm" | "md" | "lg";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  total?: number;
  dotsJump?: number;
  initialPage?: number;
  page?: number;
  siblings?: number;
  boundaries?: number;
  loop?: boolean;
  isCompact?: boolean;
  isDisabled?: boolean;
  showShadow?: boolean;
  showControls?: boolean;
  disableCursorAnimation?: boolean;
  renderItem?: (props: any) => React.ReactNode;
  getItemAriaLabel?: (page: number) => string;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  onChange?: (page: number) => void;
  className?: string;
}

/**
 *
 * @param param0 variant, color, size, radius, total, dotsJump, initialPage, page, siblings, boundaries, loop, isCompact, isDisabled, showShadow, showControls, disableCursorAnimation, renderItem, getItemAriaLabel, disableAnimation, classNames, onChange, className
 * @returns NextUI Pagination
 */
const NextPagination: React.FC<NextPaginationProps> = ({
  variant = "flat",
  color = "primary",
  size = "md",
  radius = "none",
  total = 1,
  dotsJump = 5,
  initialPage = 1,
  page,
  siblings = 1,
  boundaries = 1,
  loop = false,
  isCompact = false,
  isDisabled = false,
  showShadow = false,
  showControls = false,
  disableCursorAnimation = false,
  renderItem,
  getItemAriaLabel,
  disableAnimation = false,
  classNames,
  onChange,
  className,
}) => {
  return (
    <Pagination
      variant={variant}
      color={color}
      size={size}
      radius={radius}
      total={total}
      dotsJump={dotsJump}
      initialPage={initialPage}
      page={page}
      siblings={siblings}
      boundaries={boundaries}
      loop={loop}
      isCompact={isCompact}
      isDisabled={isDisabled}
      showShadow={showShadow}
      showControls={showControls}
      disableCursorAnimation={disableCursorAnimation}
      renderItem={renderItem}
      getItemAriaLabel={getItemAriaLabel}
      disableAnimation={disableAnimation}
      classNames={classNames}
      onChange={onChange}
      className={className}
    />
  );
};

export default NextPagination;
