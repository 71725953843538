import React from "react";
import { Progress } from "@heroui/react";

interface ProgressBarProps {
  label?: string;
  size?: "sm" | "md" | "lg";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger";
  radius?: "none" | "sm" | "md" | "lg" | "full";
  value?: number;
  valueLabel?: string;
  minValue?: number;
  maxValue?: number;
  formatOptions?: object;
  isIndeterminate?: boolean;
  isStriped?: boolean;
  showValueLabel?: boolean;
  isDisabled?: boolean;
  disableAnimation?: boolean;
  classNames?: { [key: string]: string };
  className?: string;
}

/**
 *
 * @param param0 label, size, color, radius, value, valueLabel, minValue, maxValue, formatOptions, isIndeterminate, isStriped, showValueLabel, isDisabled, disableAnimation, classNames
 * @returns NextUI Progress
 */
const ProgressBar: React.FC<ProgressBarProps> = ({
  label,
  size = "md",
  color = "primary",
  radius = "full",
  value,
  valueLabel,
  minValue = 0,
  maxValue = 100,
  formatOptions = { style: "percent" },
  isIndeterminate = false,
  isStriped = false,
  showValueLabel = false,
  isDisabled = false,
  disableAnimation = false,
  classNames,
  className,
}) => {
  return (
    <Progress
      label={label}
      size={size}
      color={color}
      radius={radius}
      value={value}
      valueLabel={valueLabel}
      minValue={minValue}
      maxValue={maxValue}
      formatOptions={formatOptions}
      isIndeterminate={isIndeterminate}
      isStriped={isStriped}
      showValueLabel={showValueLabel}
      isDisabled={isDisabled}
      disableAnimation={disableAnimation}
      classNames={classNames}
      className={className}
    />
  );
};

export default ProgressBar;
