import React from "react";
import { Skeleton } from "@heroui/react";

interface NextSkeletonProps {
  children?: React.ReactNode;
  isLoaded?: boolean;
  disableAnimation?: boolean;
  className?: string;
  classNames?: { [key: string]: string };
}

/**
 *
 * @param param0 children, isLoaded, disableAnimation, className, classNames
 * @returns NextUI Skeleton
 */
const NextSkeleton: React.FC<NextSkeletonProps> = ({
  children,
  isLoaded = false,
  disableAnimation = false,
  className,
  classNames,
}) => {
  return (
    <Skeleton
      isLoaded={isLoaded}
      disableAnimation={disableAnimation}
      classNames={classNames}
      className={className}
    >
      {children}
    </Skeleton>
  );
};

export default NextSkeleton;
