import React, { useCallback } from "react";
import { Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { handleRangePickerChange } from "/app/src/helpers/time";
import NextSwitch from "/app/src/components/NextUi/Switch";

const { RangePicker } = DatePicker;
type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

/**
 * Component for displaying the controls for the logs table
 * Controls are search, date range, and status
 */
export default function Controls({
  setSearchString,
  setAfterTime,
  setBeforeTime,
  setOnlyManualChecks,
  onlyManualChecks,
}: {
  setSearchString: (searchString: string) => void;
  setAfterTime: (time: string) => void;
  setBeforeTime: (time: string) => void;
  setOnlyManualChecks?: (onlyManualChecks: boolean) => void;
  onlyManualChecks?: boolean;
}) {
  const { t } = useTranslation();
  //handler for user changing the range of dates
  const onOk = useCallback(
    (value: RangeValue) => {
      handleRangePickerChange(value, setBeforeTime, setAfterTime);
    },
    [setAfterTime, setBeforeTime],
  );
  const handleToggle = useCallback(
    (value) => {
      setOnlyManualChecks(value);
    },
    [setOnlyManualChecks],
  );
  const handleSearch = useCallback(
    (value) => {
      setSearchString(value);
    },
    [setSearchString],
  );
  const onRangeChange = useCallback(
    (value) => {
      onOk(value);
    },
    [onOk],
  );
  return (
    <div className="flex items-center justify-between space-x-4 pt-1">
      <div className="flex-1">
        <Input.Search
          onSearch={handleSearch}
          size="large"
          placeholder={t("translation:search")}
        />
      </div>
      <div className="flex-1">
        <RangePicker
          allowClear
          allowEmpty={[true, true]}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          onChange={onRangeChange}
          size="large"
        />
      </div>
      {setOnlyManualChecks && (
        <div className="flex items-center">
          <NextSwitch
            isSelected={onlyManualChecks}
            onValueChange={handleToggle}
            size="md"
          >
            <div className="text-sm">{t("translation:only_manual_checks")}</div>
          </NextSwitch>
        </div>
      )}
    </div>
  );
}
